.image-picker {
  text-align: center;
}

.image-picker > .image-picker-preview {
  /* ... */
}

.image-picker > .image-picker-dims-hint {
  color: var(--misc-text)
}

.image-picker > .image-picker-size-hint {
  color: var(--misc-text)
}

.image-picker > .image-picker-input {
  width: 100%;
}
