.create-user-report-page {
  display: grid;
  grid-gap: 10px;
  align-items: end;
  grid-template-columns: 1fr auto auto;
  grid-template-areas: "reason submit cancel"
                       "post   post   post";
}

.create-user-report-page > .create-user-report-page-reason {
  grid-area: reason;
}

.create-user-report-page > .create-user-report-page-submit {
  grid-area: submit;
}

.create-user-report-page > .create-user-report-page-cancel {
  grid-area: cancel;
}

.create-user-report-page > .create-user-report-page-status-post {
  grid-area: post;
}
