.character-page {
  display: grid;
  row-gap: 10px;
  column-gap: 20px;

  grid-template-rows: auto auto auto auto 1fr;
  grid-template-columns: 240px  1fr   auto;
  grid-template-areas:   "image title actions"
                         "image about about"
                         "roles about about"
                         "staff about about"
                         "xxxxx about about";

  color: var(--main-text);
}

.character-page > .character-page-image {
  grid-area: image;
  border-radius: 4px;

  width: 100%;
}

.character-page > .character-page-roles {
  grid-area: roles;

  padding: 12px;
  border-radius: 8px;
  background: var(--main-background);

  display: grid;
  grid-gap: 12px;
}

.character-page > .character-page-actors:not(:empty) {
  grid-area: staff;

  padding: 12px;
  border-radius: 8px;
  background: var(--main-background);

  display: grid;
  grid-gap: 12px;
}

.character-page > .character-page-name {
  grid-area: title;
  margin: auto 0;

  font-size: 20px;
  font-weight: bold;
  color: var(--main-text);
}

.character-page-actions {
  grid-area: actions;

  display: grid;
  grid-gap: 8px;
  margin-bottom: auto;
  grid-template-columns: auto auto auto;
}

.character-page > .character-page-content {
  grid-area: about;
  margin-bottom: auto;

  padding: 8px 12px;
  border-radius: 4px;

  background: var(--main-background);
  color: var(--main-text);
}

.character-page > .character-page-content:empty::before {
  content: 'No description yet...';

  display: block;
  padding: 8px 0;

  color: var(--misc-text);
  font-style: italic;
}
