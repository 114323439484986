.media-name-form {
  display: grid;
  column-gap: 8px;
  align-items: center;
  grid-template-columns: 1fr   100px    120px   100px;
  grid-template-areas:   "text language variant remove";
}

.media-name-form > .media-name-form-text { grid-area: text }
.media-name-form > .media-name-form-language { grid-area: language }
.media-name-form > .media-name-form-variant { grid-area: variant }
.media-name-form > .button.delete { grid-area: remove }
