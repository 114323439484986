@keyframes error-fade {
  from { opacity: 0 }
  to { opacity: 1 }
}

.error-message {
  display: flex;

  padding: 8px;
  border-radius: 4px;

  position: fixed;
  top: 70px;
  left: 27vw;
  right: 27vw;

  background: var(--error-background);
  animation: error-fade 0.5s;

  /* This should always appear on top of everything else: */
  z-index: 9001;
}

.error-message .text {
  flex: 1;
  text-align: center;
  margin: auto 0;

  color: var(--error-text);
}

.error-message .hide {
  margin: auto 0;
  margin-left: 16x;

  cursor: pointer;
  padding: 8px;
  border: none;
  background: none;

  font-size: 0;
  color: var(--error-text);
}

.error-message .hide::before {
  font-size: 20px;
  content: '×';
}
