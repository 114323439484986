.status-post-list {
  /* ... */
}

.status-post-list > .status-post-list-head:not(:empty) {
  margin-bottom: 8px;
  border-bottom: 1px solid #CCC;
  padding-bottom: 8px;
}

.status-post-list > .status-post-list-body {
  display: grid;
  row-gap: 12px;
}

.status-post-list > .status-post-list-body:empty::before {
  color: var(--misc-text);
  font-style: italic;
  content: 'No posts yet...';
}

.status-post-list > .status-post-list-foot {
  text-align: center;
  padding-top: 8px;
}
