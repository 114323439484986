.user-card {
  display: inline-grid;
  grid-gap: 12px;

  margin: 12px;
  border-radius: 8px;
  padding: 12px;

  background: var(--main-background);
}

.user-card > .user-card-avatar {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  margin: auto;

  line-height: 0;
}

.user-card > .user-card-name {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  margin: auto;

  font-weight: bold;
  color: var(--main-text);
}
