.create-account {
  width: 500px;
  margin: auto;
  padding: 16px;
  border-radius: 8px;

  display: grid;
  grid-gap: 16px;
  grid-template-areas: "header   header"
                       "name     email"
                       "password confirm"
                       "footer   footer";

  background: var(--main-background);
}

.create-account > .create-account-header {
  grid-area: header;

  font-size: 20px;
  font-weight: bold;
  text-align: center;

  color: var(--main-text);
}

.create-account > .create-account-name {
  grid-area: name;
}

.create-account > .create-account-email {
  grid-area: email;
}

.create-account > .create-account-password {
  grid-area: password;
}

.create-account > .create-account-confirm {
  grid-area: confirm;
}

.create-account > .create-account-footer {
  grid-area: footer;

  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
  grid-template-areas: "rules submit";
}

.create-account-submit-button {
  grid-area: submit;
}

.create-account-rules-checkbox {
  grid-area: rules;

  color: var(--main-text);
}
