.character-actor-form {
  display: grid;
  grid-gap: 10px;
  align-items: end;
  grid-template-columns: 1fr 160px auto;
}

.character-actor-form > .character-actor-form-actor {
  /* ... */
}

.character-actor-form > .character-actor-form-language {
  /* ... */
}

.character-actor-form > .character-actor-form-remove {
  /* ... */
}
