.edit-profile-page {
  display: grid;
  row-gap: 8px;
  column-gap: 16px;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 200px 1fr;
  grid-template-areas: "avatar name"
                       "avatar about"
                       "links  about";
}

.edit-profile-page > .edit-profile-page-name { grid-area: name }
.edit-profile-page > .edit-profile-page-about { grid-area: about }
.edit-profile-page > .edit-profile-page-avatar { grid-area: avatar }

.edit-profile-page > .edit-profile-page-links {
  grid-area: links;

  display: grid;
  grid-gap: 8px;
  margin-bottom: auto;
}
