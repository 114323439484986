.user-report-summary {
  border-radius: 4px;
  padding: 8px 12px;

  display: grid;
  align-items: start;
  row-gap: 10px;
  column-gap: 20px;
  grid-template-columns: 150px 1fr 1fr 70px;
  grid-template-areas: "post bad  good dismiss"
                       "text text text text";

  background: var(--main-background);
}

.user-report-summary > .user-report-summary-link,
.user-report-summary > .user-report-summary-poster,
.user-report-summary > .user-report-summary-reporter {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-align: left;
  margin-right: auto;
}

.user-report-summary > .user-report-summary-link {
  grid-area: post;

  font-weight: bold;
  border-radius: 4px;
  padding: 0 8px;

  background: var(--menu-background);
  color: var(--menu-text);
}

.user-report-summary > .user-report-summary-poster {
  grid-area: bad;

  color: var(--misc-text)
}

.user-report-summary > .user-report-summary-reporter {
  grid-area: good;

  color: var(--misc-text)
}

.user-report-summary > .user-report-summary-dismiss {
  grid-area: dismiss;
  margin-left: auto;

  cursor: pointer;
  background: none;
  border: none;
  padding: 0;

  border-radius: 4px;
  padding: 0 8px;

  background: var(--delete-background);
  color: var(--delete-text);
}

.user-report-summary > .user-report-summary-dismiss:hover,
.user-report-summary > .user-report-summary-dismiss:focus {
  filter: var(--button-hover-effect);
}

.user-report-summary > .user-report-summary-reason {
  grid-area: text;

  color: var(--main-text)
}
