.edit-review-form {
  display: grid;
  grid-gap: 8px;
}

.edit-review-form > .edit-review-form-media-name {
  font-weight: bold;
  font-size: 20px;
  text-align: center;

  color: var(--main-text);
}

.edit-review-form > .edit-review-form-media-kind {
  font-weight: bold;
  text-align: center;

  color: var(--misc-text)
}

.edit-review-form > .edit-review-form-summary {
  /* ... */
}

.edit-review-form > .edit-review-form-content {
  /* ... */
}
