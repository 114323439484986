.media-association-form {
  display: grid;
  grid-gap: 10px;
  align-items: end;
  grid-template-columns: 160px 1fr auto;
}

.media-association-form > .media-association-form-kind {
  /* ... */
}

.media-association-form > .media-association-form-media {
  /* ... */
}

.media-association-form > .media-association-form-remove {
  /* ... */
}
