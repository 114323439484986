.user-media-list-item {
  padding: 8px 12px;
  border-radius: 4px;

  background: var(--main-background);
  color: var(--main-text);
}

.user-media-list-item > .user-media-list-item-head {
  display: grid;
  align-items: center;
  column-gap: 8px;
  grid-template-columns: 1fr 120px 50px 50px;
  grid-template-areas: "name  progress score expand"
                       "notes notes    notes notes";
}

.user-media-list-item .user-media-list-item-name {
  grid-area: name;

  cursor: pointer;
  background: none;
  border: none;
  padding: 2px 0;

  text-align: left;
  font-weight: bold;
  margin-right: auto;

  color: var(--main-text);
}

.user-media-list-item .user-media-list-item-head > .user-media-list-item-score {
  grid-area: score;

  text-align: center;
  border-radius: 99px;

  background: var(--main-text);
  color: var(--main-background);
}

.user-media-list-item .user-media-list-item-score:not(:empty)::after {
  content: '%'
}

.user-media-list-item .user-media-list-item-progress {
  grid-area: progress;

  display: flex;
  font-size: 11px;
}

.user-media-list-item .user-media-list-item-progress > .current { /* ... */ }
.user-media-list-item .user-media-list-item-progress > .total { margin: 0 4px }
.user-media-list-item .user-media-list-item-progress > .kind { flex: 1 }

.user-media-list-item .user-media-list-item-progress .total:not(:empty):before {
  content: '/ ';
  color: var(--misc-text);
}

.user-media-list-item .user-media-list-item-expand {
  grid-area: expand;

  cursor: pointer;
  border: none;
  padding: 4px;
  border-radius: 4px;

  font-size: 11px;

  background: var(--create-background);
  color: var(--create-text);
}

.user-media-list-item .user-media-list-item-expand.on {
  background: var(--general-button-background);
  color: var(--general-button-text);
}

.user-media-list-item .user-media-list-item-expand:hover,
.user-media-list-item .user-media-list-item-expand:focus {
  filter: var(--button-hover-effect);
}

.user-media-list-item .user-media-list-item-notes {
  grid-area: notes;
  font-size: 12px;
  color: var(--misc-text);
}

.user-media-list-item .user-media-list-item-notes:not(:empty) {
  margin-top: 6px
}

.user-media-list-item > .user-media-list-item-body {
  margin-top: 8px;
  border-top: 1px dotted var(--misc-text);
  padding-top: 8px;
}
