.user-review-list-item {
  padding: 8px;
  border-radius: 4px;
  background: var(--main-background);

  display: grid;
  column-gap: 8px;

  grid-template-columns: auto 1fr;
  grid-template-areas: "summary summary"
                       "kind    name";
}

.user-review-list-item > .user-review-list-item-name {
  grid-area: name;

  margin: auto;
  margin-left: 0;

  cursor: pointer;
  border: none;
  background: none;
  padding: 0;

  color: var(--misc-text);
}

.user-review-list-item > .user-review-list-item-kind {
  grid-area: kind;
  font-weight: bold;

  color: var(--misc-text);
}

.user-review-list-item > .user-review-list-item-summary {
  grid-area: summary;
  font-weight: bold;

  margin: auto;
  margin-left: 0;

  cursor: pointer;
  border: none;
  background: none;
  padding: 0;

  color: var(--main-text)
}

.user-review-list-item > .user-review-list-item-kind::after { content: ':' }
