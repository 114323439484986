.user-media-list {
  display: grid;
  row-gap: 8px;
}

.user-media-list > .user-media-list-filter {
  padding: 8px;
  border-radius: 4px;

  background: var(--menu-background);
}

.user-media-list .user-media-list-filter-option {
  cursor: pointer;
  border: none;
  background: none;
  padding: 4px 8px;

  color: var(--misc-text);
}

/* These buttons are disabled whilst the data is being fetched: */
.user-media-list-filter-option:not(.active)[disabled] { cursor: wait }

/* If they're not disabled, indicate that they're clickable: */
.user-media-list-filter-option:not(.active):not([disabled]):hover,
.user-media-list-filter-option:not(.active):not([disabled]):focus {
  color: var(--menu-text);
}

.user-media-list .user-media-list-filter-option.active {
  border-radius: 4px;

  background: var(--primary-button-background);
  color: var(--primary-button-text);
}

.user-media-list > .user-media-list-items {
  display: grid;
  row-gap: 8px;
}

.user-media-list > .user-media-list-items:empty::before {
  color: var(--misc-text);
  font-style: italic;
  content: 'Nothing here yet...';
}
