@keyframes notification-icon-body-fade {
  from { opacity: 0 }
  to { opacity: 1 }
}

.notification-icon { height: 40px }

.notification-icon > .notification-icon-head {
  cursor: pointer;
  border: none;
  padding: 0;
  background: none;

  width: 40px;
  height: 100%;
}

.notification-icon > .notification-icon-body {
  position: absolute;
  right: 9vw;
  margin-top: 8px;
  width: 280px;

  max-height: 300px;
  overflow-y: auto;

  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  background: var(--main-background);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

  animation: notification-icon-body-fade 0.5s;
}

.notification-icon > .notification-icon-body:empty::before {
  display: block;
  padding: 20px;
  content: 'No notifications...';
  font-style: italic;
  color: var(--misc-text);
}

.notification-icon .notification-count {
  display: inline-block;
  padding: 2px 10px;
  border-radius: 8px;
  color: var(--misc-text)
}

.notification-icon .notification-count.some {
  color: var(--create-text);
  background: var(--create-background);
}

.notification-icon .notification-count.null { /* ... */ }
.notification-icon .notification-count.none { /* ... */ }
