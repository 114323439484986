.media-tag {
  cursor: help;
  display: inline-block;

  margin: 3px;
  padding: 2px 10px;
  border-radius: 99px;
  font-size: 13px;

  background: var(--menu-background);
  color: var(--menu-text);
}
