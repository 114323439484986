.media-search {
  display: grid;
  grid-gap: 8px;
}

.media-search > .media-search-filter {
  padding: 8px 12px;
  border-radius: 4px;

  display: grid;
  align-items: center;
  grid-gap: 12px;
  grid-template-columns: 1fr 105px 195px 140px 40px 130px;
  grid-template-areas: "search kind status order direction ignore";

  background: var(--main-background);
  color: var(--main-text);
}

.media-search > .media-search-filter > .media-search-filter-search {
  grid-area: search;
}

.media-search > .media-search-filter > .media-search-filter-kind {
  grid-area: kind;
}

.media-search > .media-search-filter > .media-search-filter-status {
  grid-area: status;
}

.media-search > .media-search-filter > .media-search-filter-order {
  grid-area: order;
}

.media-search > .media-search-filter > .media-search-filter-direction {
  grid-area: direction;
}

.media-search > .media-search-filter > .media-search-filter-ignore {
  grid-area: ignore;
  margin-top: 24px;
}

.media-search > .media-search-items {
  text-align: center;
}

.media-search > .media-search-more {
  text-align: center;
}

.media-search .media-search-filter-direction-icon {
  cursor: pointer;
  border: none;

  width: 100%;
  height: 38px;
  border-radius: 4px;

  background: var(--primary-button-background);
}

.media-search .media-search-filter-direction-icon:hover,
.media-search .media-search-filter-direction-icon:focus {
  filter: var(--button-hover-effect)
}
