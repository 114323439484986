.tracking-site-link {
  margin: auto;
  margin-left: 0;

  display: inline-block;
  padding-left: 30px;

  background-size: contain;
  background-repeat: no-repeat;
}

.tracking-site-link.anidb { background-image: url('anidb.png') }
.tracking-site-link.anilist { background-image: url('anilist.png') }
.tracking-site-link.animePlanet { background-image: url('anime-planet.png') }
.tracking-site-link.kitsu {   background-image: url('kitsu.png') }
.tracking-site-link.mal {   background-image: url('mal.png') }
