.avatar { border-radius: 4px }

.avatar.small {
  width: 40px;
  height: 40px;
}

.avatar.large {
  width: 160px;
  height: 160px;
}
