.media-details {
  padding: 8px 12px;
  border-radius: 4px;

  display: grid;
  grid-template-columns: 140px   160px  130px  1fr;
  grid-template-areas:   "kind   start  subtype extra"
                         "status finish country count"
                         "about  about  about  about"
                         "tags   tags   tags   tags";

  background: var(--main-background);
  color: var(--main-text);
}

.media-details .media-details-kind { grid-area: kind }
.media-details .media-details-subtype { grid-area: subtype }
.media-details .media-details-country { grid-area: country }
.media-details .media-details-status { grid-area: status }
.media-details .media-details-start { grid-area: start }
.media-details .media-details-end { grid-area: finish }
.media-details .media-details-episodes { grid-area: count }
.media-details .media-details-volumes { grid-area: extra }
.media-details .media-details-studio { grid-area: extra }

.media-details .media-details-kind,     .media-details .media-details-subtype,
.media-details .media-details-country,  .media-details .media-details-status,
.media-details .media-details-start,    .media-details .media-details-end,
.media-details .media-details-episodes, .media-details .media-details-volumes,
.media-details .media-details-studio {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 60px 1fr;
}

.media-details-start > .media-details-value:empty::before,
.media-details-end > .media-details-value:empty::before {
  content: 'unknown';
  font-style: italic;
  color: var(--misc-text);
}

/* Chapters/Volumes/Episodes: */
.media-details .media-details-count { text-align: right; font-weight: bold }
.media-details .media-details-units { /* ... */ }

/* Everything else: */
.media-details .media-details-label { text-align: right; font-weight: bold }
.media-details .media-details-value { /* ... */ }

.media-details .media-details-description {
  grid-area: about;

  margin-top: 8px;
  border-top: 1px solid var(--misc-text);
}

.media-details .media-details-description:empty::before {
  content: 'No description yet...';

  display: block;
  padding: 8px 0;

  color: var(--misc-text);
  font-style: italic;
}

.media-details .media-details-tags { grid-area: tags }
