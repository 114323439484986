.character-role-form {
  display: grid;
  grid-gap: 10px;
  align-items: end;
  grid-template-columns: 1fr 160px auto;
}

.character-role-form > .character-role-form-media {
  /* ... */
}

.character-role-form > .character-role-form-kind {
  /* ... */
}

.character-role-form > .character-role-form-remove {
  /* ... */
}
