.character-search {
  display: grid;
  grid-gap: 4px;
}

.character-search > .character-search-filter {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
  align-items: end;
}

.character-search .character-search-filter > .character-search-filter-search {
  /* ... */
}

.character-search .character-search-filter > .character-search-extras {
  /* ... */
}

.character-search > .character-search-more { text-align: center }
.character-search > .character-search-items { text-align: center }
.character-search > .character-search-items > * { margin: 8px }

