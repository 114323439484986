.list-import-row {
  padding: 8px 12px;
  border-radius: 4px;

  display: grid;
  grid-gap: 8px;
  grid-template-columns: 80px 1fr 80px 200px;

  background: var(--main-background);
  color: var(--main-text);
}

.list-import-row.fail {
  background: var(--error-background);
  color: var(--error-text);
}

.list-import-row > .list-import-row-status {
  font-weight: bold;
}

.list-import-row > .list-import-row-name {
  /* ... */
}

.list-import-row > .list-import-row-kind {
  /* ... */
}

.list-import-row > .list-import-row-errors {
  /* ... */
}
