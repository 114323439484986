.edit-entry-form {
  display: grid;
  row-gap: 12px;
  column-gap: 8px;
  grid-template-areas: "episodes volumes status score"
                       "notes    notes   notes  notes"
                       "remove   remove  submit submit";
}

.edit-entry-form > .edit-entry-form-episodes { grid-area: episodes }
.edit-entry-form > .edit-entry-form-volumes { grid-area: volumes }
.edit-entry-form > .edit-entry-form-status { grid-area: status }
.edit-entry-form > .edit-entry-form-score { grid-area: score }
.edit-entry-form > .edit-entry-form-notes { grid-area: notes }

.edit-entry-form > .edit-entry-form-submit {
  grid-area: submit;
  margin-left: auto;
}

.edit-entry-form > .edit-entry-form-remove {
  grid-area: remove;
  margin-right: auto;
}
