.studio-card {
  cursor: pointer;
  border: none;

  margin: 8px;
  border-radius: 8px;

  width: 300px;
  height: 240px;

  display: inline-grid;
  grid-template-rows: 1fr auto;
  vertical-align: bottom;

  padding: 16px;
  grid-gap: 16px;

  background: var(--main-background);
  color: var(--main-text);
}

.studio-card > .studio-card-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;
  border-radius: 4px;
  padding: 0 8px;

  /* Some logo images (like Animation Do) assume a light background, so: */
  background: #FFF;

  /* Firefox doesn't need this, but Chrome doesn't work without it: */
  height: 100%;
}

.studio-card > .studio-card-logo-container > .studio-card-logo-image {
  width: 100%;
}

.studio-card > .studio-card-name {
  font-size: 20px;
  font-weight: bold;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.studio-card:hover, .studio-card:focus { filter: var(--button-hover-effect); }
