.status-post {
  padding: 12px;
  border-radius: 8px;

  background: var(--main-background);
  color: var(--main-text);

  display: grid;
  column-gap: 8px;
  grid-template-rows: 20px 20px 1fr auto;
  grid-template-columns: 40px    auto    1fr     auto    auto    auto;
  grid-template-areas:   "icon   name    name    edited  replies likes"
                         "icon   created actions actions replies likes"
                         "text   text    text    text    text    text"
                         "users  users   users   users   users   users";
}

.status-post > .status-post-user-icon {
  grid-area: icon;
}

.status-post > .status-post-user-name {
  grid-area: name;

  cursor: pointer;
  border: none;
  padding: 0;
  background: none;
  margin-right: auto;

  color: #48F;
}

.status-post > .status-post-posted-at {
  grid-area: created;

  font-size: 12px;
  color: var(--misc-text);
}

.status-post > .status-post-edited-at {
  grid-area: edited;

  padding-top: 5px;
  font-size: 12px;
  font-style: italic;
  color: var(--misc-text);
}

.status-post > .status-post-actions {
  grid-area: actions;

  position: relative;
  top: -2px;
  margin-right: auto;

  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto auto;
}

.status-post > .status-post-actions > .status-post-report {
  cursor: pointer;
  border: none;
  padding: 0;

  font-size: 12px;
  font-weight: bold;

  background: none;
  color: var(--delete-background);
}

.status-post > .status-post-actions > .status-post-edit {
  cursor: pointer;
  border: none;
  padding: 2px 12px;

  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;

  background: var(--primary-button-background);
  color: var(--primary-button-text);
}

.status-post > .status-post-actions > .status-post-delete {
  cursor: pointer;
  border: none;
  padding: 2px 4px;

  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;

  background: var(--delete-background);
  color: var(--delete-text);
}

.status-post .status-post-report:focus, .status-post .status-post-report:hover,
.status-post .status-post-delete:focus, .status-post .status-post-delete:hover,
.status-post .status-post-edit:focus, .status-post .status-post-edit:hover {
  filter: var(--button-hover-effect);
}

.status-post > .status-post-reply-count {
  grid-area: replies;

  cursor: pointer;
  border: none;

  background: none;
  color: var(--main-text);

  padding: 4px 6px;
  border-radius: 4px;

  margin-bottom: auto;
}

.status-post > .status-post-like-count {
  grid-area: likes;

  cursor: pointer;
  border: none;

  background: none;
  color: var(--main-text);

  padding: 4px 6px;
  border-radius: 4px;

  margin-bottom: auto;
}

.status-post > .status-post-like-count[disabled] { cursor: not-allowed }
.status-post-like-count.you-like { background: var(--error-background) }

.status-post > .status-post-content {
  grid-area: text;

  margin-top: 8px;
}

.status-post > .status-post-content > .reveal-spoiler {
  cursor: pointer;
  border: none;
  padding: 4px 8px;
  border-radius: 2px;

  background: var(--highlight-background);
  color: var(--main-text);
}

.status-post > .status-post-content > .reveal-spoiler::before {
  content: '⚠️';
  margin-right: 6px;
}

.status-post > .status-post-like-users:not(:empty) {
  grid-area: users;

  margin-top: 8px;
  border-top: 1px dotted var(--misc-text);
  padding-top: 6px;
}
