.markdown-editor {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto auto;
  grid-template-areas: "editor  editor  editor"
                       "preview preview preview"
                       "help    extras  actions";
}

.markdown-editor > .editor {
  grid-area: editor;

  resize: vertical;
  min-height: 100px;

  border-radius: 4px;
  padding: 8px 12px;

  border: 1px solid var(--misc-text);
  background: var(--main-background);
  color: var(--main-text);
}

.markdown-editor > .preview {
  grid-area: preview;
  padding: 8px 12px;

  border: 2px solid var(--highlight-background);
  border-radius: 8px;

  background: var(--main-background);
  color: var(--main-text);
}

.markdown-editor > .help-text {
  grid-area: help;
  margin: auto 0;
  color: var(--main-text)
}

.markdown-editor > .extras {
  grid-area: extras;

  color: var(--main-text)
}

.markdown-editor > .actions {
  grid-area: actions;
}

.markdown-editor > .preview:empty { display: none }
