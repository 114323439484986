.edit-staff-form {
  display: grid;
  row-gap: 8px;
  column-gap: 16px;

  grid-template-rows: auto 1fr;
  grid-template-columns: 240px 1fr;
  grid-template-areas: "image name"
                       "image about";
}

.edit-staff-form > .edit-staff-form-name { grid-area: name }
.edit-staff-form > .edit-staff-form-about { grid-area: about }
.edit-staff-form > .edit-staff-form-picture { grid-area: image }
