/* This is the "container" for the drop-down (and possibly a clear button). */
.simple-dropdown {
  display: flex;
}

/* This isn't part of the DOM, but makes it clearer that it's a drop-down. */
.simple-dropdown::before {
  content: '▼';
  color: #CCC;
  width: 0;

  z-index: 1;
  position: relative;
  top: 9px;
  left: 13px;
}

/* This is the "current value" box. */
.simple-dropdown > .simple-dropdown-value {
  flex: 1;

  cursor: pointer;
  border: 1px solid var(--misc-text);
  padding: 7px 12px;
  padding-left: 32px; /* For the ▼ prefix. */

  background: var(--main-background);
  color: var(--main-text);

  min-height: 38px; /* Don't shrink vertically when no value is selected. */
  line-height: 16px;

  overflow: hidden;
  text-overflow: ellipsis;

  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  /* Remove the browser-specific "drop-down box" styling: */
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* Indicate when it is disabled: */
.simple-dropdown.disabled { opacity: 0.5 }
.simple-dropdown.disabled, .simple-dropdown.disabled * { cursor: not-allowed }

/* This is the "clear" button, which may not always be present. */
.simple-dropdown > .simple-dropdown-clear {
  cursor: pointer;
  border: none;
  padding: 8px 12px;

  background: #dc3545;
  color: #FFF;
}

/* This could be either the value OR the clear button, if the latter exists: */
.simple-dropdown > *:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* This is an individual option within the drop-down. */
.simple-dropdown > .simple-dropdown-value > .simple-dropdown-option {
  /* <option> elements can't generally be themed... :( */
}

/* We need a blank option for the "clear" butto to work - but don't show it! */
.simple-dropdown > .simple-dropdown-value > .simple-dropdown-option.blank {
  display: none; /* This DOES work, even if most other CSS doesn't. */
}
