.social-page {
  display: grid;
  row-gap: 20px;
  column-gap: 30px;
  grid-template-rows: auto auto auto auto 1fr;
  grid-template-columns: 380px 1fr;
  grid-template-areas: "recent-anime  posts"
                       "recent-manga  posts"
                       "anime-reviews posts"
                       "manga-reviews posts"
                       "free-space    posts";
}

.social-page-status-posts { grid-area: posts }
.social-page-recent-anime { grid-area: recent-anime }
.social-page-recent-manga { grid-area: recent-manga }
.social-page-recent-anime-reviews { grid-area: anime-reviews }
.social-page-recent-manga-reviews { grid-area: manga-reviews }
