.media-card {
  margin: 6px;
  width: 240px;
  height: 284px;

  display: inline-grid;
  grid-template-rows: auto 1fr;
  grid-template-areas: "title"
                       "about";
}

.media-card > .media-card-title {
  grid-area: title;
  z-index: 1;

  margin: 12px auto;
  margin-bottom: auto;

  cursor: pointer;
  border: none;

  padding: 0 8px;
  border-radius: 8px;

  font-size: 18px;
  font-weight: bold;


  background: var(--menu-background);
  color: var(--menu-text);
}

.media-card > .media-card-image-container {
  grid-area: title/about;
  overflow-y: hidden;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.media-card > .media-card-image-container > .media-card-image {
  width: 100%;
  height: 360px;
  margin-top: -60px;
}

.media-card > .media-card-details {
  grid-area: about;
  align-self: end;

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  padding: 12px 8px;
  font-variant: small-caps;
  font-size: 15px;

  background: var(--menu-background);
  color: var(--menu-text);

  display: grid;
  grid-gap: 12px;
  grid-template-columns: auto auto auto;
  justify-content: center;
}

.media-card > .media-card-details > .media-card-country {
  /* ... */
}

.media-card > .media-card-details > .media-card-subtype {
  /* ... */
}

.media-card > .media-card-details > .media-card-score {
  /* ... */
}
