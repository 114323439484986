.media-review-summary {
  padding: 8px;
  border-radius: 4px;
  background: var(--main-background);
}

.media-review-summary > .summary {
  display: block;

  cursor: pointer;
  border: none;
  background: none;
  padding: 0;

  font-weight: bold;

  color: var(--main-text);
}

.media-review-summary > .user-name {
  display: block;

  cursor: pointer;
  border: none;
  background: none;
  padding: 0;

  color: var(--misc-text);
}
