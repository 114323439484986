.status-post-editor .status-post-editor-footer {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
}

.status-post-editor .status-post-editor-footer > .status-post-editor-toggles {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;

  margin-top: 8px;
}

.status-post-editor .status-post-editor-footer > .status-post-editor-extras {
  /* ... */
}
