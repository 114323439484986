.tab-group {
  /* ... */
}

.tab-group > .body {
  /* ... */
}

.tab-group > .tabs {
  display: flex;
  border-bottom: 1px solid var(--primary-button-background);
  margin-bottom: 8px;
}

.tab-group > .tabs > .tab {
  cursor: pointer;
  margin-left: 8px;

  border: none;
  padding: 8px 12px;

  background: var(--general-button-background);
  color: var(--general-button-text);

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tab-group > .tabs > .tab.active {
  background: var(--primary-button-background);
  color: var(--primary-button-text);
}

.tab-group > .tabs > .tab:focus, .tab-group > .tabs > .tab:hover {
  filter: var(--button-hover-effect);
}
