.edit-character-form {
  display: grid;
  row-gap: 8px;
  column-gap: 16px;

  grid-template-rows: auto auto auto 1fr;
  grid-template-columns: 240px 1fr;
  grid-template-areas: "image name"
                       "image roles"
                       "image staff"
                       "image about";
}

.edit-character-form > .edit-character-form-name { grid-area: name }
.edit-character-form > .edit-character-form-about { grid-area: about }
.edit-character-form > .edit-character-form-picture { grid-area: image }

.edit-character-form > .edit-character-form-roles {
  grid-area: roles;

  display: grid;
  grid-gap: 8px;
}

.edit-character-form > .edit-character-form-actors {
  grid-area: staff;

  display: grid;
  grid-gap: 8px;
}
