.user-admin-page {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
  grid-template-areas: "title actions"
                       "name  name"
                       "about about";
}

.user-admin-page > .user-admin-page-title {
  grid-area: title;

  font-size: 20px;
  font-weight: bold;
  margin: auto 0;
  color: var(--main-text);
}

.user-admin-page > .user-admin-page-actions {
  grid-area: actions;

  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto auto;
}

.user-admin-page > .user-admin-page-name {
  grid-area: name;
}

.user-admin-page > .user-admin-page-about {
  grid-area: about;
}

.user-admin-page .user-admin-page-editor-extras {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto auto;
  align-items: center;
}
