.user-page {
  display: grid;
  row-gap: 8px;
  column-gap: 30px;
  grid-template-rows: 40px auto auto auto 1fr;
  grid-template-columns: 380px 1fr auto auto;

  grid-template-areas: "image yyyy  role actions"
                       "image feed  feed feed"
                       "name  feed  feed feed"
                       "about feed  feed feed"
                       "zzzzz feed  feed feed";
}

.user-page > .avatar-container {
  grid-area: image;
  margin: 0 auto;
  line-height: 0;
}

.user-page > .name {
  grid-area: name;

  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: var(--main-text);
}

.user-page > .actions {
  grid-area: actions;

  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto auto;
}

.user-page > .role {
  grid-area: role;
  margin: auto;

  padding: 4px 8px;
  border-radius: 2px;
  font-weight: bold;

  background: var(--menu-background);
  color: var(--menu-text);
}

.user-page > .about {
  grid-area: about;
  padding: 8px 12px;
  border-radius: 8px;

  background: var(--main-background);
  color: var(--main-text);
}

.user-page > .status-post-container {
  grid-area: feed;
}

.user-page > .about:empty::before {
  color: var(--misc-text);
  font-style: italic;
  content: 'This user has not written an "about me" section yet.'
}
