.simple-button {
  cursor: pointer;

  border: none;
  border-radius: 8px;
  padding: 8px 12px;

  background: var(--general-button-background);
  color: var(--general-button-text);
}

.simple-button.primary {
  background: var(--primary-button-background);
  color: var(--primary-button-text);
}

.simple-button.create {
  background: var(--create-background);
  color: var(--create-text);
}

.simple-button.delete {
  background: var(--delete-background);
  color: var(--delete-text);
}

.simple-button:focus, .simple-button:hover {
  filter: var(--button-hover-effect)
}

.simple-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
