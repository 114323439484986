.all-user-reports-page {
  display: grid;
  grid-gap: 8px;
}

.all-user-reports-page:empty::before {
  color: var(--misc-text);
  font-style: italic;
  content: 'No user reports to display.';
}
