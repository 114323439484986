.character-card {
  cursor: pointer;
  border: none;
  padding: 0;

  width: 240px;
  border-radius: 8px;
  vertical-align: bottom;

  display: inline-grid;
  grid-template-rows: 35px 240px 25px;

  background: var(--main-background);
  color: var(--main-text);
}

.character-card > .character-card-image-container {
  height: 100%;
  overflow: hidden;
}

.character-card > .character-card-image-container > .character-card-image {
  width: 100%;
}

.character-card > .character-card-name {
  padding: 0 16px;
  font-size: 16px;
  font-weight: bold;

  align-self: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.character-card > .character-card-info {
  padding: 0 16px;
  font-variant: small-caps;
  font-size: 15px;
}
