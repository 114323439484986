.studio-page {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
  grid-template-areas: "name    actions"
                       "content content"
}

.studio-page > .studio-page-name {
  grid-area: name;

  font-size: 20px;
  font-weight: bold;
  color: var(--main-text);
}

.studio-page > .studio-page-actions {
  grid-area: actions;

  display: grid;
  grid-gap: 8px;
  margin-bottom: auto;
  grid-template-columns: auto auto;
}

.studio-page > .studio-page-content {
  grid-area: content;
}
