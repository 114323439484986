.recent-reviews {
  padding: 16px;
  border-radius: 8px;

  background: var(--main-background);
  color: var(--main-text);
}

.recent-reviews-title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 12px;
}

.recent-reviews-items {
  display: grid;
  grid-gap: 12px;
}

.recent-reviews-items:empty::before {
  content: 'Nothing yet...';
  font-style: italic;
  color: var(--misc-text);
}
