.user-data-page {
  display: grid;
  row-gap: 20px;
  column-gap: 10px;
  grid-template-columns: 1fr auto auto auto;
  grid-template-areas: "header export clear-anime clear-manga"
                       "import import import      import"
                       "result result result      result";
}

.user-data-page > .user-data-page-title {
  grid-area: header;
  margin: auto 0;

  font-size: 20px;
  font-weight: bold;

  color: var(--main-text);
}

.user-data-page > .user-data-page-export { grid-area: export }
.user-data-page > .user-data-page-clear-anime { grid-area: clear-anime }
.user-data-page > .user-data-page-clear-manga { grid-area: clear-manga }
.user-data-page > .user-data-page-import { grid-area: import }

.user-data-page > .user-data-page-results:not(:empty)::before {
  content: 'Data import results:';
  font-size: 16px;
  font-weight: bold;
  color: var(--main-text);
}

.user-data-page > .user-data-page-results {
  grid-area: result;

  display: grid;
  grid-gap: 8px;
}
