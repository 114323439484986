.change-email-form {
  display: grid;
  grid-gap: 8px;
  align-items: end;
  grid-template-columns: 1fr 1fr 50px;
  grid-template-areas: "email password submit";
}

.change-email-form > .change-email-form-email { grid-area: email }
.change-email-form > .change-email-form-password { grid-area: password }
.change-email-form > .change-email-form-submit { grid-area: submit }
