.nav-bar {
  display: flex;
  padding: 8px 9vw;
  background: var(--menu-background);

  position: fixed;
  width: 100vw;

  /* Don't allow things with a Z-index to appear on top of this: */
  z-index: 9000;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.nav-bar > .brand {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  line-height: 0;
}

.nav-bar > .brand:focus, .nav-bar > .brand:hover {
  filter: brightness(1.1);
}

.nav-bar > .brand > img {
  width: auto;
  height: 40px;
}

.nav-bar > .links {
  flex: 1;
  text-align: center;
  margin: 0 8px;
  white-space: nowrap;
}

.nav-bar > .links > .link {
  cursor: pointer;
  background: none;
  border: none;
  color: var(--menu-text);

  font-size: 13px;
  font-weight: bold;

  padding: 0 8px;
  height: 100%;
}

.nav-bar > .links > .link:hover, .nav-bar > .links > .link:focus {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
}

.nav-bar > .user-actions {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 8px;
}

.nav-bar > .user-actions > .current-user {
  cursor: pointer;

  padding: 0;
  border: none;
  background: none;

  display: flex;
  align-items: center;
}

.nav-bar > .user-actions > .current-user > .name {
  color: var(--menu-text);
  font-weight: bold;
  margin-left: 8px;
}
