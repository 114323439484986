.user-report-icon {
  cursor: pointer;
  background: none;
  border: none;

  border-radius: 4px;
  color: var(--misc-text)
}

.user-report-icon.some {
  color: var(--delete-text);
  background: var(--delete-background);
}

.user-report-icon.none:hover,
.user-report-icon.none:focus { background: rgba(255, 255, 255, 0.08) }

.user-report-icon.some:hover,
.user-report-icon.some:focus { filter: var(--button-hover-effect) }
