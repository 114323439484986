.edit-media-form {
  display: grid;
  row-gap: 8px;
  column-gap: 16px;

  grid-template-rows: auto auto auto auto 1fr;
  grid-template-columns: 240px   1fr    1fr     1fr     1fr      1fr;
  grid-template-areas:  "picture name   name    name    name     name"
                        "picture kind   subtype country studio   studio"
                        "picture status start   end     episodes volumes"
                        "picture anidb  anilist ap      kitsu    mal"
                        "picture about  about   about   about    about";
}

.edit-media-form > .edit-media-form-picture { grid-area: picture }

.edit-media-form > .edit-media-form-name { grid-area: name }
.edit-media-form > .edit-media-form-kind { grid-area: kind }
.edit-media-form > .edit-media-form-subtype { grid-area: subtype }
.edit-media-form > .edit-media-form-country { grid-area: country }

.edit-media-form > .edit-media-form-studio { grid-area: studio }
.edit-media-form > .edit-media-form-status { grid-area: status }
.edit-media-form > .edit-media-form-start-date { grid-area: start }
.edit-media-form > .edit-media-form-end-date { grid-area: end }
.edit-media-form > .edit-media-form-episodes { grid-area: episodes }
.edit-media-form > .edit-media-form-volumes { grid-area: volumes }

.edit-media-form > .edit-media-form-site-anidb { grid-area: anidb }
.edit-media-form > .edit-media-form-site-anilist { grid-area: anilist }
.edit-media-form > .edit-media-form-site-anime-planet { grid-area: ap }
.edit-media-form > .edit-media-form-site-kitsu { grid-area: kitsu }
.edit-media-form > .edit-media-form-site-mal { grid-area: mal }

.edit-media-form > .edit-media-form-about { grid-area: about }

.edit-media-form > .edit-media-form-name .edit-media-form-name-list {
  display: grid;
  grid-gap: 8px;
}

.edit-media-form .edit-media-form-markdown-editor-footer {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto auto auto;
  align-items: center;
}
