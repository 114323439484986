.notification-summary {
  display: grid;
  column-gap: 8px;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "icon name name"
                       "icon link okay";

  padding: 10px;
}

.notification-summary + .notification-summary {
  border-top: 1px solid var(--misc-text)
}

.notification-summary > * {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-align: left;
}

.notification-summary > .notification-summary-icon {
  grid-area: icon;
}

.notification-summary > .notification-summary-user {
  grid-area: name;

  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: bold;
  color: var(--main-text);
}

.notification-summary > .notification-summary-link {
  grid-area: link;

  color: var(--misc-text);
}

.notification-summary > .notification-summary-delete {
  grid-area: okay;

  border-radius: 4px;
  padding: 0 8px;
  font-size: 12px;

  background: var(--delete-background);
  color: var(--delete-text);
}

.notification-summary > .notification-summary-delete:hover,
.notification-summary > .notification-summary-delete:focus {
  filter: var(--button-hover-effect)
}

