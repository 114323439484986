.tag-form {
  display: grid;
  grid-gap: 8px;
  align-items: end;
  grid-template-columns: 150px 1fr auto auto;
}

.tag-form > .tag-form-name {

}

.tag-form > .tag-form-description {

}

.tag-form > .tag-form-submit {

}

.tag-form > .tag-form-extra {

}
