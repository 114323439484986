.status-post-page {
  display: grid;
  grid-gap: 20px;
}

.status-post-page > .status-post-page-thread {
  /* ... */
}

.status-post-page > .status-post-page-replies {
  margin-left: 20px;
  display: grid;
  grid-gap: 8px;
}

.status-post-page > .status-post-page-add-reply {
  /* ... */
}
