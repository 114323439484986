.log-in {
  width: 400px;
  margin: auto;
  padding: 16px;
  border-radius: 8px;

  display: grid;
  grid-gap: 16px;

  background: var(--main-background);
}

.log-in > .log-in-header {
  font-size: 20px;
  font-weight: bold;
  text-align: center;

  color: var(--main-text);
}

.log-in > .log-in-email {
  /* ... */
}

.log-in > .log-in-email {
  /* ... */
}

.log-in > .log-in-footer {
  text-align: right;
}
