.user-review-list {
  display: grid;
  row-gap: 8px;
}

.user-review-list:empty::before {
  color: var(--misc-text);
  font-style: italic;
  content: 'No reviews yet...';
}
