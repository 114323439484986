.character-actor {
  cursor: pointer;
  background: none;
  border: none;
  padding: 2px 8px;

  text-align: left;
  border-radius: 4px;

  display: grid;
  grid-template-columns: 30px 1fr;
  grid-template-areas: "lang text";
}

.character-actor > .character-actor-name {
  grid-area: text;
  color: var(--main-text);
}

.character-actor > .character-actor-language {
  grid-area: lang;
  color: var(--misc-text);
}

.character-actor:hover, .character-actor:focus {
  background: var(--highlight-background);
  filter: var(--button-hover-effect);
}
