.input-label {
  /* ... */
}

.input-label > .input-label-text {
  display: block;
  margin-bottom: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: bold;
  color: var(--main-text)
}
