.edit-account-page {
  display: grid;
  grid-gap: 8px;

  width: 500px;
  margin: auto;
}

.edit-account-page > .edit-account-page-section {
  padding: 16px;
  border-radius: 4px;

  background: var(--main-background);
}

.edit-account-page .edit-account-page-section-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;

  color: var(--main-text);
}

.edit-account-page .edit-account-page-warning {
  margin-bottom: 16px;
  font-weight: bold;
  text-align: center;

  color: var(--error-text);
}

.edit-account-page .edit-account-page-delete {
  text-align: center;
}
