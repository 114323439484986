.standard-layout {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.standard-layout .standard-layout-content {
  flex: 1;
  background: var(--highlight-background);
  padding: 16px 8vw;

  /* The navbar is fixed, so add some extra spacing for it: */
  padding-top: 80px;
}
