.all-tags-page {
  display: grid;
  grid-gap: 8px;
}

.all-tags-page > .all-tags-page-items {
  display: grid;
  grid-gap: 8px;
}

.all-tags-page-items:empty::before {
  color: var(--misc-text);
  font-style: italic;
  content: 'No tags yet...';
}
