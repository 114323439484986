.staff-search {
  display: grid;
  grid-gap: 4px;
}

.staff-search > .staff-search-filter {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
  align-items: end;
}

.staff-search .staff-search-filter > .staff-search-filter-search {
  /* ... */
}

.staff-search .staff-search-filter > .staff-search-extras {
  /* ... */
}

.staff-search > .staff-search-more { text-align: center }
.staff-search > .staff-search-items { text-align: center }
.staff-search > .staff-search-items > * { margin: 8px }

