.change-password-form {
  display: grid;
  grid-gap: 8px;
  align-items: end;
  grid-template-columns: 1fr 1fr 1fr 50px;
  grid-template-areas: "old new confirm submit";
}

.change-password-form > .change-password-form-old-password { grid-area: old }
.change-password-form > .change-password-form-new-password { grid-area: new }
.change-password-form > .change-password-form-confirm { grid-area: confirm }
.change-password-form > .change-password-form-submit { grid-area: submit }
