.studio-details {
  display: grid;

  padding: 16px;
  border-radius: 4px;

  background: var(--main-background);
  color: var(--main-text);
}

.studio-details > .studio-details-logo {
  max-height: 160px;
  margin: auto;
  margin-bottom: 10px;
}

.studio-details > .studio-details-sites:not(:empty) {
  text-align: center;
  margin-bottom: 10px;
}

.studio-details > .studio-details-sites > a { margin: 0 8px }

.studio-details > .studio-details-about {
  /* ... */
}

.studio-details > .studio-details-about:empty::before {
  content: 'No description yet...';
  color: var(--misc-text);
  font-style: italic;
}
