.review-page {
  display: grid;
  row-gap: 8px;
  column-gap: 4px;

  grid-template-areas: "summary summary"
                       "media   user"
                       "content content"
                       "actions actions";
}

.review-page > .review-page-summary {
  grid-area: summary;

  font-size: 20px;
  font-weight: bold;
  text-align: center;

  color: var(--main-text);
}

.review-page > .review-page-media {
  grid-area: media;

  margin: 0;
  margin-left: auto;

  cursor: pointer;
  border: none;
  background: none;
  padding: 0;

  color: var(--main-text);
}

.review-page > .review-page-user {
  grid-area: user;

  margin: 0;
  margin-right: auto;

  cursor: pointer;
  border: none;
  background: none;
  padding: 0;

  color: var(--main-text);
}

.review-page > .review-page-content {
  grid-area: content;

  padding: 16px 24px;
  border-radius: 8px;

  background: var(--main-background);
  color: var(--main-text);
}

.review-page > .review-page-actions {
  grid-area: actions;
  margin-left: auto;

  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto auto;
}

.review-page > .review-page-media::before {
  content: 'A review of ';
  color: var(--misc-text);
}

.review-page > .review-page-user::before {
  content: 'by ';
  color: var(--misc-text);
}
