.all-moderators-page {
  display: grid;
  grid-gap: 8px;
}

.all-moderators-page > .all-moderators-page-title {
  font-weight: bold;
  font-size: 20px;
  color: var(--main-text);
}

.all-moderators-page > .all-moderators-page-user-list {
  text-align: center;
}
