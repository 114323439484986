.simple-checkbox {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 20px 1fr;
  grid-template-areas: "input label";
}

.simple-checkbox > .simple-checkbox-label {
  grid-area: label;
}

.simple-checkbox > .simple-checkbox-input {
  grid-area: input;

  cursor: pointer;
  font-size: 0;
  border-radius: 2px;

  padding: 0;
  width: 20px;
  height: 20px;

  border: 1px solid var(--misc-text);
  background: var(--main-background);
}

.simple-checkbox.on > .simple-checkbox-input::before {
  content: '✓';
  display: inline-block;

  line-height: 0px;
  margin-top: 5px;
  font-size: 15px;
  font-weight: bold;

  color: var(--main-text);
}

.simple-checkbox > .simple-checkbox-input[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
