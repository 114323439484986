.upload-file-form {
  padding: 16px;
  border-radius: 8px;

  display: grid;
  grid-gap: 10px;

  background: var(--main-background);
  color: var(--main-text);
}

.upload-file-form-title {
  font-size: 16px;
  font-weight: bold;
}

.upload-file-form-help-text {
  /* ... */
}

.upload-file-form-input {
  cursor: pointer;
  border: 1px solid var(--misc-text);
  padding: 7px 12px;
  border-radius: 4px;

  min-height: 38px; /* Don't shrink vertically when no value is selected. */
  line-height: 16px;

}

.upload-file-form-submit {
  text-align: right;
}
