.character-role {
  cursor: pointer;
  background: none;
  border: none;
  padding: 4px 12px;
  padding-bottom: 8px;

  border-left: 1px dotted var(--misc-text);

  text-align: left;

  display: grid;
  row-gap: 8px;
  column-gap: 6px;
  align-items: center;
  grid-template-columns: auto auto 1fr;
  grid-template-areas:  "name name name"
                        "kind role nsfw";
}

.character-role > .character-role-media-name {
  grid-area: name;

  font-weight: bold;
  font-size: 16px;

  color: var(--main-text);
}

.character-role > .character-role-media-kind {
  grid-area: kind;

  padding: 2px 8px;
  border-radius: 4px;

  background: var(--menu-background);
  color: var(--menu-text);
}

.character-role > .character-role-kind {
  grid-area: role;

  color: var(--misc-text);
}

.character-role-nsfw {
  grid-area: nsfw;
}

.character-role:hover, .character-role:focus {
  background: var(--highlight-background);
  filter: var(--button-hover-effect);
}
