.edit-media-tags-page {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
  grid-template-areas: "name back"
                       "list list"
                       "form form";
}

.edit-media-tags-page > .edit-media-tags-page-name {
  grid-area: name;

  font-size: 20px;
  font-weight: bold;
  color: var(--main-text);
}

.edit-media-tags-page > .edit-media-tags-page-list {
  grid-area: list;

  display: grid;
  grid-gap: 8px;
}

.edit-media-tags-page-list-item {
  border-radius: 4px;
  padding: 8px;
  background: var(--main-background);

  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
}

.edit-media-tags-page-list-item-name {
  color: var(--main-text);
}

.edit-media-tags-page > .edit-media-tags-page-add-new-tag-form {
  grid-area: form;

  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
}

.edit-media-tags-page > .edit-media-tags-page-back-button {
  grid-area: back;

  margin: auto;
}
