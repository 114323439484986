.recent-review-item {
  cursor: pointer;
  text-align: left;
  background: none;
  border: none;
  padding: 0;

  padding: 4px 8px;
  border-left: 3px solid var(--misc-text);

  display: grid;
  grid-template-areas: "summary"
                       "user-name"
                       "media-name";

  color: var(--main-text);
}

.recent-review-item-summary {
  grid-area: summary;

  font-size: 16px;
  font-weight: bold;
}

.recent-review-item-media-name {
  grid-area: media-name;

  padding-top: 4px;
  color: var(--misc-text);
}

.recent-review-item-user-name { grid-area: user-name }
.recent-review-item-user-name::before { content: 'by ' }

.recent-review-item:hover, .recent-review-item:focus {
  background: var(--highlight-background);
  filter: var(--button-hover-effect);
}
