.simple-input {
  width: 100%;
  border-radius: 4px;
  padding: 8px 12px;

  border: 1px solid var(--misc-text);
  background:  var(--main-background);
  color:  var(--main-text);
}

.simple-input[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.simple-input[disabled][title] { cursor: help }
