.page-footer {
  padding: 8px 10vw;
  background: var(--menu-background);

  display: flex;
  justify-content: center;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.page-footer > * {
  margin: 0 8px;
  text-decoration: none;
  color: var(--misc-text);
}

.page-footer > *:focus, .page-footer > *:hover {
  color: var(--menu-text);
}

.page-footer > button {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
}

.page-footer > .page-footer-manage-tags { margin-right: auto }
