.media-page {
  display: grid;
  row-gap: 10px;
  column-gap: 20px;

  grid-template-rows: auto auto auto auto auto 1fr;
  grid-template-columns: 240px 1fr auto;
  grid-template-areas: "image title   actions"
                       "image content content"
                       "lists content content"
                       "names content content"
                       "sites content content"
                       "xxxxx content content";
}

.media-page > .media-page-image {
  grid-area: image;
  border-radius: 4px;

  width: 100%;
  height: 360px;
}

.media-page > .media-page-main-name {
  grid-area: title;
  margin: auto 0;

  font-size: 20px;
  font-weight: bold;
  color: var(--main-text);
}

.media-page > .media-page-actions {
  grid-area: actions;

  display: grid;
  grid-gap: 8px;
  margin-bottom: auto;
  grid-template-columns: auto auto auto;
}

.media-page > .media-page-list-actions {
  grid-area: lists;
}

.media-page > .media-page-name-list {
  grid-area: names;

  display: grid;
  grid-gap: 8px;

  padding: 8px 16px;
  border-radius: 8px;
  background: var(--main-background);
}

.media-page > .media-page-tracking-site-list:not(:empty) {
  grid-area: sites;

  padding: 8px 16px;
  border-radius: 8px;
  background: var(--main-background);

  display: grid;
  grid-gap: 8px;
}

.media-page > .media-page-content {
  grid-area: content;
}

/* TODO: Perhaps this should be a component? */

.media-page-name-item {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-template-areas: "lang text";
}

.media-page-name-item > .media-page-name-item-language {
  grid-area: lang;
  color: var(--misc-text);
}

.media-page-name-item > .media-page-name-item-text {
  grid-area: text;
  color: var(--main-text);
}
