.edit-studio-form {
  display: grid;
  grid-gap: 8px;
  grid-template-areas: "logo  logo    logo  logo  logo"
                       "name  name    name  name  name"
                       "anidb anilist ap    kitsu mal"
                       "about about   about about about";
}

.edit-studio-form > .edit-studio-form-logo {
  grid-area: logo;

  padding: 16px;
  border-radius: 16px;

  background: var(--menu-background);
  color: var(--menu-text);
}

.edit-studio-form > .edit-studio-form-name {
  grid-area: name;
}

.edit-studio-form > .edit-studio-form-site-anidb {
  grid-area: anidb;
}

.edit-studio-form > .edit-studio-form-site-anilist {
  grid-area: anilist;
}

.edit-studio-form > .edit-studio-form-site-anime-planet {
  grid-area: ap;
}

.edit-studio-form > .edit-studio-form-site-kitsu {
  grid-area: kitsu;
}

.edit-studio-form > .edit-studio-form-site-mal {
  grid-area: mal;
}

.edit-studio-form > .edit-studio-form-about {
  grid-area: about;
}
