.recent-media-item {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;

  text-align: left;
  margin-right: auto;

  color: var(--main-text);
}
