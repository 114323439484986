.media-review-list {
  /* ... */
}

.media-review-list > .media-review-list-items {
  display: grid;
  grid-gap: 8px;

  margin-bottom: 16px;
}

.media-review-list > .media-review-list-items:empty::before {
  content: 'No reviews yet...';

  color: var(--misc-text);
  font-style: italic;
}
